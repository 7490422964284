export default class DDMDataLayerHandler {
  constructor() {
    this.channelName = this._getChannelName();
  }

  addViewOffersListEvent(offers) {
    const eventAlredyExists = this._eventExists('view_item_list');

    if (eventAlredyExists) return;

    this._addEventToDataLayer({
      event: 'view_item_list',
      ecommerce: {
        item_list_id: 'carrossel_lp',
        item_list_name: 'Carrossel Landing Page',
        items: offers.map(offer => ({
          item_id: offer.id,
          item_name: offer.name,
          affiliation: this.channelName,
          item_brand: offer.brand,
          item_category: offer.category,
          price: offer.price,
        })),
      },
    });
  }

  addSelectOfferEvent(offer) {
    this._clearEcommerceKey();

    this._addEventToDataLayer({
      event: 'select_item',
      ecommerce: {
        item_list_id: 'carrossel_lp',
        item_list_name: 'Carrossel Landing Page',
        items: [
          {
            item_id: offer.id,
            item_name: offer.name,
            affiliation: this.channelName,
            item_brand: offer.brand,
            item_category: offer.category,
            price: offer.price,
          },
        ],
      },
    });
  }

  addViewOfferEvent(offer) {
    this._setOfferInGlobalScope(offer);

    this._addEventToDataLayer({
      event: 'view_item',
      ecommerce: {
        currency: 'BRL',
        value: offer.price,
        items: [
          {
            item_id: offer.id,
            item_name: offer.name,
            affiliation: this.channelName,
            item_brand: offer.brand,
            item_category: offer.category,
            price: offer.price,
          },
        ],
      },
    });
  }

  addOfferConversionEvent() {
    const { id, name, brand, category, price } = window.offerData;

    this._addEventToDataLayer({
      event: 'add_to_cart',
      ecommerce: {
        currency: 'BRL',
        value: price,
        items: [
          {
            item_id: id,
            item_name: name,
            affiliation: this.channelName,
            item_brand: brand,
            item_category: category,
            price,
          },
        ],
      },
    });
  }

  _addEventToDataLayer(event) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push(event);
  }

  _setOfferInGlobalScope(offerData) {
    window.offerData = offerData;
  }

  _eventExists(event) {
    if (!window.dataLayer) return false;

    const eventExists = window.dataLayer.some(data => data.event === event);

    return eventExists;
  }

  _clearEcommerceKey() {
    this._addEventToDataLayer({ ecommerce: null });
  }

  _getChannelName() {
    const channelNameWrapper = document.querySelector(
      "meta[name='auto_layer_dealer_name']",
    );

    if (!channelNameWrapper) return '';

    const channelName = channelNameWrapper.content;

    return channelName;
  }
}
