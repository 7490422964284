import { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import FeaturesDisplay from '../DDM/FeaturesDisplay';

export default class PsaCard extends Component {
  formatted_price() {
    return `R$ ${this.props.price}`;
  }

  usedModelSealList() {
    const { exchange, km, fuelText } = this.props;
    const items = [
      {
        label: 'Câmbio',
        value: exchange,
      },
      {
        label: 'Quilometragem',
        value: km && `${km} KM`,
      },
      {
        label: 'Combustível',
        value: fuelText,
      },
    ];
    const cleanList = items.filter(item => !!item.value);
    return cleanList;
  }

  render() {
    const {
      title,
      subtitle,
      image,
      link,
      lazy,
      sealList,
      isUsedModel,
      offer,
      showBadge,
      badge,
      fabricationModelYear,
      onClickCard,
    } = this.props;

    const {
      secondary_call,
      main_call,
      featured,
      description,
      features,
      has_features_list,
    } = offer;

    return (
      <div
        className={classNames('card psa-card', {
          'card--used-model': isUsedModel,
        })}
      >
        {fabricationModelYear && (
          <span class="badge--primary badge badge--pill">
            {fabricationModelYear}
          </span>
        )}
        <div className="card__header-wrapper">
          <div className="card__header">
            <div class="card__used-vehicles__badges"></div>
            <h3 className="card__title" data-match-height="card__title">
              {title}
            </h3>
            {subtitle && <h4 className="card__subtitle">{subtitle}</h4>}
          </div>
          <a className="card__link" href={link} onClick={onClickCard}>
            <img
              className={classNames('card__image', {
                'swiper-lazy': lazy,
              })}
              src={image}
              alt={title}
              width="200"
              height="145"
              data-match-height="card__image"
            />
          </a>
        </div>
        <div className="card__content">
          <ul className="card__highlights" data-match-height="card__highlights">
            {sealList.map(item => (
              <li className="card__highlight">{item}</li>
            ))}
            {isUsedModel &&
              this.usedModelSealList().map(item => (
                <li className="card__highlight highlight--used-model">
                  <b>{item.label}:</b> {item.value}
                </li>
              ))}
          </ul>
          <div className="card__price">
            {isUsedModel ? (
              <div>
                <p className="card__price-title">Por apenas</p>
                <p className="card__price-value">{this.formatted_price()}</p>
              </div>
            ) : (
              <div
                className="card__price-wrapper"
                data-match-height="card__price-wrapper"
              >
                {secondary_call && (
                  <p className="card__starting-price">{secondary_call}</p>
                )}
                {main_call && (
                  <small className="card__text-by">{main_call}</small>
                )}
                {featured && (
                  <div className="card__promotional-price">{featured}</div>
                )}
                {description && (
                  <div className="card__description">{description}</div>
                )}

                {has_features_list && features && (
                  <FeaturesDisplay features={features} />
                )}
              </div>
            )}
          </div>
          <a
            className="btn button button--primary w-100"
            href={link}
            onClick={onClickCard}
          >
            Tenho interesse
          </a>
        </div>

        {showBadge && (
          <div className="psa-card__badge">
            <small>{badge}</small>
          </div>
        )}
      </div>
    );
  }
}

PsaCard.defaultProps = {
  title: '',
  subtitle: '',
  image: '',
  link: '',
  price: '',
  exchange: '',
  km: '',
  fuelText: '',
  sealList: [],
  lazy: false,
  isUsedModel: false,
  offer: {},
  showBadge: false,
  badge: '',
  fabricationModelYear: '',
  onClickCard: () => {},
};

PsaCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  image: PropTypes.string,
  link: PropTypes.string,
  price: PropTypes.string,
  exchange: PropTypes.string,
  km: PropTypes.string,
  fuelText: PropTypes.string,
  sealList: PropTypes.arrayOf(PropTypes.string),
  lazy: PropTypes.bool,
  isUsedModel: PropTypes.bool,
  offer: PropTypes.shape({
    secondary_call: PropTypes.string,
    main_call: PropTypes.string,
    featured: PropTypes.string,
    description: PropTypes.string,
    features: PropTypes.arrayOf(PropTypes.string),
    has_features_list: PropTypes.bool,
  }),
  showBadge: PropTypes.bool,
  badge: PropTypes.string,
  fabricationModelYear: PropTypes.string,
  onClickCard: PropTypes.func,
};
