import { Component } from 'preact';
import PropTypes from 'prop-types';
import {
  setPerPage,
  setList,
  nextPage,
  incrementList,
  setMethodApi,
} from '../../../../stores/SearchFilter';
import CardCitroen from '../../../Cards/UsedVehiclesCitroen';
import CardPeugeot from '../../../Cards/Psa';
import PlaceholderCard from '../../../Cards/Placeholder';
import { setShowNewVehicle } from '../../../../stores/ListUsedModels';

class ListUsedVehiclesApp extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingMore: false,
      list: [],
    };

    const { perPage, showNewVehicles } = this.props;

    setMethodApi('getUsedModels');
    setShowNewVehicle(showNewVehicles);
    setPerPage(perPage);
    setList();

    window.store.SearchFilter.watch(state => {
      this.setState({
        list: state.list,
        loading: state.loading,
        loadingMore: state.loadingMore,
        page: state.page,
        total_pages: state.total_pages,
        shouldLoadMore: state.page < state.total_pages,
        showList: !state.showConversionForm,
      });

      this.formatList();
    });
  }

  formatList() {
    const { urlPrefix } = this.props;
    const formattedVehicles = this.state.list.map(vehicle => {
      return {
        title: vehicle.name,
        image: vehicle.image || vehicle.item_image.middle_image,
        link: urlPrefix + '/' + vehicle.slug,
        price: vehicle.price,
        km: vehicle.km,
        exchange: vehicle.exchange,
        fuelText: vehicle.fuel_text,
        brand: vehicle.brand,
        fabricationModelYear:
          vehicle.fabrication_year + '/' + vehicle.model_year,
      };
    });

    this.setState({
      formattedList: formattedVehicles,
    });
  }

  handleLoadMore(e) {
    e.preventDefault();
    nextPage();
    incrementList();
  }

  componentDidUpdate() {
    window.matchHeight.match('.used-vehicles-listing__list');
  }

  componentDidMount() {
    window.matchHeight.match('.used-vehicles-listing__list');
  }

  shouldShowList() {
    return this.state.list.length > 0;
  }

  fakeList() {
    const totalVehicles = Number(this.props.totalVehicles);
    const fakeArray = Array(totalVehicles).fill();
    return fakeArray;
  }

  render() {
    const { formattedList, shouldLoadMore, loadingMore, loading, showList } =
      this.state;

    const { isPeugeot } = this.props;

    if (showList) {
      return (
        <div class="used-vehicles-listing__content">
          <div class="used-vehicles-listing__list">
            {formattedList.length > 0
              ? formattedList.map(vehicle => {
                  return isPeugeot ? (
                    <CardPeugeot
                      class="used-vehicles-listing__item"
                      title={vehicle.title}
                      image={vehicle.image}
                      link={vehicle.link}
                      price={vehicle.price}
                      km={vehicle.km}
                      exchange={vehicle.exchange}
                      fuelText={vehicle.fuelText}
                      brand={vehicle.brand}
                      fabricationModelYear={vehicle.fabricationModelYear}
                      isUsedModel={true}
                    ></CardPeugeot>
                  ) : (
                    <CardCitroen
                      class="used-vehicles-listing__item"
                      title={vehicle.title}
                      image={vehicle.image}
                      link={vehicle.link}
                      price={vehicle.price}
                      km={vehicle.km}
                      exchange={vehicle.exchange}
                      fuelText={vehicle.fuelText}
                      brand={vehicle.brand}
                      fabricationModelYear={vehicle.fabricationModelYear}
                    ></CardCitroen>
                  );
                })
              : this.fakeList().map(_ => <PlaceholderCard />)}
          </div>

          {(shouldLoadMore || loadingMore) && (
            <div class="used-vehicles-listing__load-more-items">
              <a
                href="#"
                class="btn button button--ghost"
                onClick={this.handleLoadMore}
              >
                {loadingMore ? (
                  <div class="used-vehicles__loading">
                    <div className="mr-2 spinner-border small" role="status">
                      <span className="sr-only">Carregando...</span>
                    </div>
                    &nbsp; Carregando...
                  </div>
                ) : (
                  'Carregar mais veículos'
                )}
              </a>
            </div>
          )}
        </div>
      );
    }
  }
}

ListUsedVehiclesApp.defaultProps = {
  perPage: 12,
  list: [],
  formattedList: [],
  totalVehicles: 8,
  urlPrefix: '',
  isPeugeot: false,
  showNewVehicles: false,
};
ListUsedVehiclesApp.propTypes = {
  perPage: PropTypes.number,
  totalVehicles: PropTypes.number,
  urlPrefix: PropTypes.string,
  isPeugeot: PropTypes.boolean,
  showNewVehicles: PropTypes.boolean,
};

export default ListUsedVehiclesApp;
